import React, { useState, useMemo } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import cx from "classnames";

import {
  SubWorksiteNavigation,
  SubWorkspaceNavigation,
  AgreementSubNavigation,
  ReferenceGroupSubNavigation,
} from "./SubNavigations";
import RootNavigation from "./RootNavigation";
import { VerticalToHorizontalLayout } from "../components/Layout";
import { useWindowSize, mobileWindowWidth } from "../helpers";

import logoUrl from "../images/logo-white.svg";
import { ReactComponent as MenuIcon } from "../images/menu-24px.svg";
import { ReactComponent as CloseIcon } from "../images/close.svg";
import styles from "./MainNavigation.module.css";

function Navi({
  policies = {},
  superadmin,
  documentationLibraryAdmin,
  userInfo,
  logOut,
}) {
  return (
    <Switch>
      <Route
        path={[
          "/workspaces",
          "/worksites",
          "/worksites/new",
          "/frame_agreements",
          "/frame_agreements/new",
          "/reference_groups",
          "/reference_groups/new",
        ]}
        exact
        render={() => (
          <RootNavigation
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
          />
        )}
      />
      {policies.worksite?.index && (
        <Route
          path={["/worksites/:worksiteId"]}
          render={(props) => (
            <SubWorksiteNavigation
              userInfo={userInfo}
              logOut={logOut}
              {...props}
            />
          )}
        />
      )}
      <Route
        path={["/workspaces/by_identifier/:workspaceId"]}
        render={() => (
          <RootNavigation
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
          />
        )}
      />

      <Route
        path={["/workspaces/:workspaceId"]}
        render={(props) => (
          <SubWorkspaceNavigation
            userInfo={userInfo}
            logOut={logOut}
            {...props}
          />
        )}
      />
      <Route
        path={["/frame_agreements/:agreementId"]}
        render={(props) => (
          <AgreementSubNavigation
            userInfo={userInfo}
            logOut={logOut}
            {...props}
          />
        )}
      />
      <Route
        path={["/reference_groups/:referenceGroupId"]}
        render={(props) => (
          <ReferenceGroupSubNavigation
            userInfo={userInfo}
            logOut={logOut}
            {...props}
          />
        )}
      />
      <Route
        render={() => (
          <RootNavigation
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
          />
        )}
      />
    </Switch>
  );
}

/**
 * Main
 * */
function MainNavigation({
  logOut,
  userInfo,
  policies,
  superadmin,
  documentationLibraryAdmin,
  isSystemNotificationVisible,
}) {
  const size = useWindowSize();
  const isMobile = useMemo(() => mobileWindowWidth(size.width), [size.width]);
  const [menuOpen, setMenuOpen] = useState(false);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <VerticalToHorizontalLayout
      className={isSystemNotificationVisible ? styles.marginTop : ""}
    >
      {isMobile && (
        <div className={styles.mobileRibbon}>
          <NavLink to="/workspaces">
            <div className={cx(styles.logoContainer)}>
              <img src={logoUrl} alt="Sitowise Voima" />
            </div>
          </NavLink>
          <button
            className={styles.mobileMenuIcon}
            onClick={() => toggleMenu()}
          >
            {menuOpen ? <CloseIcon className="icon" /> : <MenuIcon />}
          </button>
        </div>
      )}

      <nav
        className={cx(
          `flexY wrap alignCenter childMarginsX ${
            isMobile ? styles.mobileView : ""
          } ${menuOpen ? styles.menuIsOpen : ""}`,
          styles.navigation
        )}
      >
        <div className={cx(styles.container)}>
          <h1 className={cx(styles.logoContainer, "flex alignCenter")}>
            <NavLink to="/workspaces">
              <img src={logoUrl} alt="Sitowise Voima" className={styles.logo} />
            </NavLink>
          </h1>

          <Navi
            policies={policies}
            superadmin={superadmin}
            documentationLibraryAdmin={documentationLibraryAdmin}
            userInfo={userInfo}
            logOut={logOut}
          />
        </div>
      </nav>
    </VerticalToHorizontalLayout>
  );
}

export default MainNavigation;
