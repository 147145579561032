import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import Spinner from "./components/Spinner";
import { apiUrl } from "./config/env";

import styles from "./AppMain.module.css";

const ContentSearch = lazy(() => import("./ContentSearch/ContentSearch"));
const Worksites = lazy(() => import("./Worksites/Worksites"));
const Worksite = lazy(() => import("./Worksite/Worksite"));
const WorksiteNew = lazy(() => import("./Worksite/WorksiteNew"));
const WorksiteEdit = lazy(() => import("./Worksite/WorksiteEdit"));
const ReferenceGroups = lazy(() => import("./ReferenceGroups/ReferenceGroups"));
const ReferenceGroup = lazy(() => import("./ReferenceGroup/ReferenceGroup"));
const ReferenceGroupNew = lazy(
  () => import("./ReferenceGroup/ReferenceGroupNew")
);
const ReferenceGroupEdit = lazy(
  () => import("./ReferenceGroup/ReferenceGroupEdit")
);
const Workspaces = lazy(() => import("./Workspaces/Workspaces"));
const Workspace = lazy(() => import("./Workspace/Workspace"));
const WorkspaceReclamationsOwn = lazy(
  () => import("./WorkspaceReclamationsOwn/WorkspaceReclamationsOwn")
);
const WorkspaceEdit = lazy(() => import("./Workspace/WorkspaceEdit"));
const WorkspaceFeedbackNew = lazy(
  () => import("./WorkspaceFeedbacks/WorkspaceFeedbackNew")
);
const WorkspaceFeedbackEdit = lazy(
  () => import("./WorkspaceFeedbacks/WorkspaceFeedbackEdit")
);
const ServicesLibrary = lazy(() => import("./ServicesLibrary/ServicesLibrary"));
const ServicesLibraries = lazy(
  () => import("./ServicesLibraries/ServicesLibraries")
);
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const Feedback = lazy(() => import("./Feedback/Feedback"));
const ProjectFeedback = lazy(() => import("./ProjectFeedback/ProjectFeedback")); //view
const Agreements = lazy(() => import("./Agreements/Agreements"));
const Agreement = lazy(() => import("./Agreement/Agreement"));
const AgreementEdit = lazy(() => import("./Agreement/AgreementEdit"));
const AgreementForm = lazy(() => import("./AgreementForm/AgreementForm"));
const WorkorderEdit = lazy(() => import("./Workorder/WorkorderEdit"));
const Changelog = lazy(() => import("./Changelog/Changelog"));
const ReclamationEdit = lazy(() => import("./Reclamation/ReclamationEdit"));
const RiskAssessmentEdit = lazy(
  () => import("./RiskAssessment/RiskAssessmentEdit")
);
const RiskAssessmentNew = lazy(
  () => import("./RiskAssessment/RiskAssessmentNew")
);
const ProjectReferences = lazy(
  () => import("./ProjectReferences/ProjectReferences")
);
const ExternalTool = lazy(() => import("./ExternalTool/ExternalTool"));
const NotFound = lazy(() => import("./NotFound/NotFound"));
const LegacyRedirect = lazy(() => import("./LegacyRedirect/LegacyRedirect"));

export default function AppMain({ policies, admins }) {
  const { t } = useTranslation();
  const { superadmin } = admins;

  const guidelinesDefaultSlug = "voima-pro";

  return (
    <main className={cx(styles.contentContainer)}>
      <Suspense
        fallback={<Spinner paddings>{t("General.loadingPage")}</Spinner>}
      >
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/workspaces" />} />
          <Route
            path={[`/newsroom`, `/newsroom/*`]}
            exact
            component={() => {
              window.location.replace("https://intra.sitowise.com");
              return null;
            }}
          />

          <Route
            path="/action-reporter-example"
            exact
            component={() => {
              return (
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      throw new Error(`Test error ${Math.random()}`);
                    }}
                  >
                    Throw error
                  </button>
                </div>
              );
            }}
          />

          <Route path="/feedback" exact component={Feedback} />

          {policies?.worksite?.index && (
            <Route
              path="/worksites"
              exact
              render={(routeProps) => (
                <Worksites {...routeProps} policies={policies} />
              )}
            />
          )}
          {policies?.worksite?.create && (
            <Route path="/worksites/new" exact component={WorksiteNew} />
          )}
          {policies?.referenceGroup?.index && (
            <Route path="/reference_groups" exact component={ReferenceGroups} />
          )}
          <Route
            path="/reference_groups/new"
            exact
            component={ReferenceGroupNew}
          />
          <Route
            path="/reference_groups/:referenceGroupId/edit"
            exact
            component={ReferenceGroupEdit}
          />

          <Redirect from="/projects" to="/workspaces" exact />
          <Redirect from="/offers" to="/workspaces" exact />

          <Route
            path="/reclamations/own"
            exact
            render={() => <WorkspaceReclamationsOwn />}
          />
          <Route path="/workspaces" exact component={Workspaces} />

          {policies?.worksite?.index && (
            <Route
              path="/worksites/:worksiteId/edit"
              exact
              component={WorksiteEdit}
            />
          )}

          <Route
            path="/workspaces/:workspaceId/edit"
            exact
            render={(routeProps) => <WorkspaceEdit {...routeProps} />}
          />

          {policies?.frameAgreement.index && (
            <Route path="/frame_agreements/" exact component={Agreements} />
          )}
          {policies?.frameAgreement.create && (
            <Route
              path="/frame_agreements/new"
              exact
              render={(routeProps) => <AgreementForm isNew />}
            />
          )}
          <Route
            path="/frame_agreements/:agreementId/edit"
            exact
            component={AgreementEdit}
          />
          <Route path="/references" exact component={ProjectReferences} />

          {superadmin && (
            <Route
              path={[
                "/admin",
                "/admin_settings",
                "/admin/*",
                "/admin_settings/*",
              ]}
              exact
              component={(props) => {
                const location = props.location.pathname.replace(
                  /^\/admin_settings/,
                  "/admin"
                );
                window.location.replace(`${apiUrl}${location}`);
                return null;
              }}
            />
          )}
          <Route path="/changelog" exact component={Changelog} />

          <Route
            path="/reclamations/:reclamationId/edit"
            exact
            component={ReclamationEdit}
          />
          <Route
            path="/risk_assessments/:riskAssessmentId/edit"
            exact
            component={RiskAssessmentEdit}
          />

          <Route
            path="/external/:entityType/by_id/:entityIdentifier/:linkType"
            exact
            component={ExternalTool}
          />

          <Route
            path="/external/:entityType/:entityIdentifier/:linkType"
            exact
            component={ExternalTool}
          />

          <Route
            path="/projects/by_id/:projectIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/projects/by_identifier/:projectIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/projects/by_unit4_id/:projectIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/projects/:projectIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />

          <Route
            path="/offers/by_id/:offerIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/offers/by_identifier/:offerIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/offers/by_unit4_id/:offerIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />
          <Route
            path="/offers/:offerIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />

          {policies?.worksite?.index && (
            <Route path="/worksites/:worksiteId" component={Worksite} />
          )}
          {policies?.referenceGroup?.index && (
            <Route
              path="/reference_groups/:referenceGroupId"
              component={ReferenceGroup}
            />
          )}

          <Route
            path="/workspaces/:workspaceId/workorders/:workorderId"
            exact
            component={WorkorderEdit}
          />

          <Route
            path="/workspaces/by_identifier/:workspaceIdentifier"
            render={(routeProps) => <LegacyRedirect {...routeProps} />}
          />

          <Route
            path="/workspaces/:workspaceId/risk_assessments/new"
            exact
            component={RiskAssessmentNew}
          />

          <Route
            path="/workspaces/:workspaceId/risk_assessments/:riskAssessmentId/edit"
            exact
            component={RiskAssessmentEdit}
          />

          <Route
            path="/workspaces/:workspaceIdentifier/feedbacks/:feedbackId"
            exact
            component={ProjectFeedback}
          />

          <Route
            path="/workspaces/:workspaceIdentifier/feedbacks/:surveyType/new"
            exact
            component={WorkspaceFeedbackNew}
          />

          <Route
            path="/workspaces/:workspaceIdentifier/feedbacks/:feedbackId/edit"
            exact
            component={WorkspaceFeedbackEdit}
          />

          <Route path="/workspaces/:workspaceId" component={Workspace} />

          {policies?.frameAgreement.index && (
            <Route
              path="/frame_agreements/:agreementId"
              component={Agreement}
            />
          )}
          <Route
            path="/feedbacks/:feedbackId"
            exact
            component={ProjectFeedback}
          />
          <Route path="/dashboard" component={Dashboard} />

          <Route path="/guidelines/search" exact component={ContentSearch} />
          <Route
            path={[
              "/guidelines/services/:library_slug/:project_stage_name",
              "/guidelines/services/:library_slug/:project_stage_name/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guidelines/services"
            exact
            component={ServicesLibraries}
          />
          <Route
            path="/guidelines/services/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guidelines/:library_slug/:project_stage_name/:knowledge_area_name",
              "/guidelines/:library_slug/:project_stage_name/:knowledge_area_name/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guidelines/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Redirect
            from="/guidelines"
            to={`/guidelines/${guidelinesDefaultSlug}`}
          />

          <Route path="/guides/search" exact component={ContentSearch} />
          <Route
            path={[
              "/guides/services/:library_slug/:project_stage_slug",
              "/guides/services/:library_slug/:project_stage_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guides/services/:library_slug/:project_stage_slug/:knowledge_area_slug",
              "/guides/services/:library_slug/:project_stage_slug/:knowledge_area_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route path="/guides/services" exact component={ServicesLibraries} />
          <Route
            path="/guides/services/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Route
            path={[
              "/guides/:library_slug/:project_stage_slug/:knowledge_area_slug",
              "/guides/:library_slug/:project_stage_slug/:knowledge_area_slug/versions",
            ]}
            exact
            component={ServicesLibrary}
          />
          <Route
            path="/guides/:library_slug"
            exact
            component={ServicesLibrary}
          />
          <Redirect from="/guides" to={`/guides/${guidelinesDefaultSlug}`} />

          <Route
            render={(routeProps) => (
              <NotFound {...routeProps} loadingPolicies={!policies}>
                <p>
                  <NavLink to="/">{t("General.backToHomePage")}</NavLink>
                </p>
              </NotFound>
            )}
          />
        </Switch>
      </Suspense>
    </main>
  );
}
