import * as React from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import WorksiteNavigation from "../Worksite/Navigation/WorksiteNavigation";
import WorkspaceNavigation from "../Workspace/WorkspaceNavigation";
import AgreementNavigation from "../Agreement/Navigation/AgreementNavigation";
import ReferenceGroupNavigation from "../ReferenceGroup/Navigation/ReferenceGroupNavigation";
import { useWorksite } from "../hooks/worksiteHooks";
import { useWorkspace } from "../hooks/workspaceHooks";

import styles from "./SubNavigations.module.css";
import { ReactComponent as ArrowLeft } from "../images/arrow-left.svg";

export function SubWorksiteNavigation({ match, userInfo, logOut }) {
  const { t } = useTranslation();
  const worksiteId = match.params.worksiteId;

  const { worksite, error } = useWorksite(worksiteId);

  return (
    <div className={cx(styles.navigationLinks, "flexY childMarginsY")}>
      {error && <p>{error}</p>}
      {worksite && (
        <React.Fragment>
          <NavLink className={styles.backLink} to="/worksites">
            <ArrowLeft className={styles.backLinkIcon} />{" "}
            {t("Navigation.worksites")}
          </NavLink>
          <WorksiteNavigation
            rootPath={match.url}
            userInfo={userInfo}
            logOut={logOut}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export function SubWorkspaceNavigation({ match, userInfo, logOut }) {
  const { t } = useTranslation();
  const workspaceId = match.params.workspaceId;

  const { error, workspace } = useWorkspace(workspaceId);

  return (
    <div className={cx(styles.navigationLinks, "flexY childMarginsY")}>
      {error && <p>{t("Errors.search")}</p>}
      {workspace && (
        <React.Fragment>
          <NavLink className={styles.backLink} to="/workspaces">
            <ArrowLeft className={styles.backLinkIcon} />{" "}
            {t("Navigation.workspaces")}
          </NavLink>
          <WorkspaceNavigation
            workspace={workspace}
            rootPath={match.url}
            userInfo={userInfo}
            logOut={logOut}
          />
        </React.Fragment>
      )}
    </div>
  );
}

export function AgreementSubNavigation({
  match,
  location,
  userInfo,
  logOut,
}) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.navigationLinks, "flexY childMarginsY")}>
      <NavLink className={styles.backLink} to="/frame_agreements">
        <ArrowLeft className={styles.backLinkIcon} />{" "}
        {t("Navigation.frameAgreements")}
      </NavLink>

      <AgreementNavigation
        rootPath={match.url}
        userInfo={userInfo}
        logOut={logOut}
        location={location}
      />
    </div>
  );
}

export function ReferenceGroupSubNavigation({ match, userInfo, logOut }) {
  const { t } = useTranslation();

  return (
    <div className={cx(styles.navigationLinks, "flexY childMarginsY")}>
      <NavLink className={styles.backLink} to="/reference_groups">
        <ArrowLeft className={styles.backLinkIcon} />{" "}
        {t("Navigation.referenceGroups")}
      </NavLink>

      <ReferenceGroupNavigation
        rootPath={match.url}
        userInfo={userInfo}
        logOut={logOut}
      />
    </div>
  );
}
