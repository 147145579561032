import * as React from "react";
import { useTranslation } from "react-i18next";
import SubNavLink from "../components/Navigation/SubNavLink";
import LanguageSwitcher from "../components/LanguageSwitcher";
import NaviUserInfo from "../components/NaviUserInfo";

import styles from "./WorkspaceNavigation.module.css";

export default function WorkspaceNavigation({
  workspace,
  rootPath,
  userInfo,
  logOut,
}) {
  const { t } = useTranslation();
  const isProject =
    workspace &&
    workspace.lifecycleStatus &&
    workspace.lifecycleStatus === "project";

  const showDocsTab = !!(
    workspace?.serviceTypes?.length || workspace?.serviceProducts?.length
  );

  const { policy } = workspace;

  return (
    <div className={styles.subNavigation}>
      <SubNavLink
        exact
        label={t("Navigation.basicInformation")}
        url={rootPath}
        type="workspaces"
      />

      {workspace.checklistGenerated && (
        <SubNavLink
          label={t("Navigation.checklist")}
          url={`${rootPath}/checklist`}
          disabled={!policy?.indexChecklist}
          type="workspaces"
        />
      )}

      <SubNavLink
        label={t("Navigation.workspaceTools")}
        url={`${rootPath}/workspace_tools`}
        disabled={!policy?.showWorkspaceTools}
        type="workspaces"
        showInfoCircle={isProject && workspace.provisioningOptionsAvailable}
      />

      <SubNavLink
        label={t("data.riskAssessment")}
        url={`${rootPath}/risk_assessments`}
        disabled={!policy?.indexRiskAssessments}
        type="workspaces"
      />
      <SubNavLink
        label={t("data.reclamations")}
        url={`${rootPath}/reclamations`}
        disabled={!policy?.indexReclamations}
        type="workspaces"
      />
      <SubNavLink
        label={t("Navigation.bidInformation")}
        url={`${rootPath}/bid_groups`}
        disabled={!policy?.indexBidGroup}
        type="workspaces"
      />
      <SubNavLink
        label={t("Navigation.responsibility")}
        url={`${rootPath}/responsibility_questionnaire`}
        disabled={!policy?.showResponsibilityForm}
        type="workspaces"
      />
      <SubNavLink
        label={t("data.feedback")}
        url={`${rootPath}/feedbacks`}
        type="workspaces"
        disabled={!isProject || !policy?.indexSurvey}
      />
      <SubNavLink
        label={t("Navigation.guidelines")}
        url={`${rootPath}/services_documentations`}
        disabled={!showDocsTab}
        type="workspaces"
      />
      <SubNavLink
        label={t("data.reference")}
        url={`${rootPath}/reference`}
        type="workspaces"
        disabled={!isProject}
      />
      <SubNavLink
        label={t("Navigation.membershipRequests")}
        url={`${rootPath}/membership_requests`}
        disabled={!policy?.manageMembershipRequests}
        type="workspaces"
      />
      {policy?.reimport && (
        <SubNavLink
          label={t("Navigation.admin")}
          url={`${rootPath}/admin`}
          type="workspaces"
        />
      )}
      <div className="marginTopAuto paddingTop">
        <LanguageSwitcher />
        <NaviUserInfo userInfo={userInfo} logOut={logOut} />
      </div>
    </div>
  );
}
