import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import groupBy from "lodash.groupby";
import compact from "lodash.compact";
import format from "tinydate";
import sortBy from "lodash.sortby";
import * as MicrosoftGraphTypes from "@microsoft/microsoft-graph-types";

import teamsIcon from "./images/teams-icon.png";
import networkDriveIcon from "./images/network-drive-icon.png";
import askiIcon from "./images/aski-icon.png";
import unit4Icon from "./images/unit4-icon.png";
import salesforceIcon from "./images/salesforce-icon.png";

import { securityLevels } from "./globals";
import O365Provisioner from "./lib/O365Provisioner";
import { apiUrl, appEnv } from "./config/env";

import actionReporter from "~/config/initializers/actionReporter";

export function securityLevelShortLabel(id, t) {
  const foundLevel = securityLevels(t).find((level) => level.value === id);
  if (foundLevel) {
    return foundLevel.shortLabel;
  } else {
    return "–";
  }
}

export const getServerUrl = (path) => {
  if (path.match(/^https?:.+/)) {
    return path;
  }

  path = path.match(/^[/?]/) ? path : `/${path}`;
  return `${apiUrl}${path}`;
};

export function queryParameters(search) {
  if (!search) {
    return {};
  }

  return search
    .slice(1)
    .split("&")
    .reduce((acc, segment) => {
      const pair = segment.split("=");
      acc[pair[0]] = pair[1];
      return acc;
    }, {});
}

// previously as linksForOffer and linksForProject
export function linksForWorkspace({ workspace, forSidebar, t }) {
  let links = [];

  if (forSidebar) {
    links.push({
      label: t("Links.workspacePage"),
      url: `/workspaces/${workspace.id}`,
    });
  }

  if (workspace.teamsToolExternalLinkAvailable) {
    links.push({
      external: true,
      label: "Teams",
      url: `/external/workspaces/${workspace.id}/teams`,
      icon: teamsIcon,
      redirectWithDelay: workspace.securityLevel === "privately_visible",
    });
  }

  if (workspace.unit4ToolExternalLinkAvailable) {
    links.push({
      external: true,
      label: "Unit4",
      url: `/external/workspaces/${workspace.id}/unit4`,
      icon: unit4Icon,
    });
  }

  if (workspace.askiToolExternalLinkAvailable) {
    links.push({
      external: true,
      label: "Aski",
      url: `/external/workspaces/${workspace.id}/aski`,
      icon: askiIcon,
    });
  }

  if (workspace.networkDriveToolExternalLinkAvailable) {
    links.push({
      external: true,
      label: t("Links.workspaceFolder"),
      url: `searchhost:${workspace.endUserDirectoryPath}`,
      icon: networkDriveIcon,
    });
  }

  if (workspace.legacyNetworkDirectoryPath) {
    links.push({
      external: true,
      label: t("Links.oldWorkspaceFolder"),
      url: `searchhost:${workspace.legacyNetworkDirectoryPath}`,
      icon: networkDriveIcon,
    });
  }

  if (workspace.legacySharedTeamsUrl) {
    links.push({
      external: true,
      label: t("Links.oldSharedTeams"),
      url: workspace.legacySharedTeamsUrl,
      icon: teamsIcon,
    });
  }

  if (workspace.salesforceId) {
    links.push({
      external: true,
      label: "Salesforce",
      url: `/external/workspaces/${workspace.id}/salesforce`,
      icon: salesforceIcon,
    });
  }

  return links;
}

export function linksForAgreements(agreement) {
  const links = [];

  if (agreement?.salesforceUrl) {
    links.push({
      external: true,
      label: "Salesforce",
      url: agreement.salesforceUrl,
      icon: salesforceIcon,
    });
  }

  return links;
}

export function createO365Provisioner(msClient) {
  return new O365Provisioner({
    msClient,
    environment: appEnv,
  });
}

export function identifierToUrlParam(identifier) {
  if (!identifier) {
    throw new Error("No identifier passed");
  }
  return identifier.toString().replace(/ /g, "").replace("/", "-");
}

export function getNameNumber(numberAndName) {
  const nameFragments = numberAndName.split(" / ");
  const name =
    nameFragments
      .map((s) => s.trim())
      .slice(1)
      .join(" / ") || nameFragments[0];
  const projectNumber = nameFragments[1] ? nameFragments[0] : null;

  return { name, projectNumber };
}

export function getStatusTitle(status, t, lang) {
  if (!status) {
    return "";
  }

  if (status === "Keskeytetty") {
    return t("ProjectRow.suspended");
  }
  const statusArr = status.split("/");
  switch (lang) {
    case "en":
      return statusArr[1] || status;
    default:
      return statusArr[0] || status;
  }
}

export function reclamationStatus(status, t) {
  switch (status) {
    case "indicated":
      return t("ReclamationStatus.indicated");
    case "received":
      return t("ReclamationStatus.received");
    case "closed_with_compensation":
      return t("ReclamationStatus.closedWithCompensation");
    case "closed_without_compensation":
      return t("ReclamationStatus.closedWithoutCompensation");
    default:
      return "";
  }
}

export function groupedSummary(reclamations, t) {
  if (reclamations && reclamations.length > 0) {
    const reclamationsByStatus = groupBy(
      reclamations,
      (reclamation) => reclamation.status
    );

    return Object.keys(reclamationsByStatus)
      .map(
        (status) =>
          `${reclamationsByStatus[status].length} ${reclamationStatus(
            status,
            t
          ).toLowerCase()}`
      )
      .join(", ");
  }
  return null;
}

export function safeDecodeURIComponent(value?: string): string | undefined {
  if (!value) return;

  return decodeURIComponent(value.replace(/%(?![0-9][\da-f]+)/gi, "%25"));
}

export function safeEncodeURIComponent(value?: string): string | undefined {
  if (!value) return;

  return encodeURIComponent(value.replace(/%(?![0-9][\da-f]+)/gi, "%25"));
}

export function getQueryParams(search) {
  const query = search.substr(1);
  if (!query) {
    return {};
  }
  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("&")
    .reduce((params, param) => {
      let [key, value] = param.split("=");
      params[key] = value ? decodeURIComponent(value) : "";
      return params;
    }, {});
}

export function paramsFromQuery(search) {
  if (search.indexOf("=") === -1) {
    return {};
  }
  const queryParams = {};
  for (let [key, value] of new URLSearchParams(search).entries()) {
    const normalizedValue = normalizeQueryParamValue(value);
    if (key.indexOf("[]") > -1) {
      const normalizedKey = key.slice(0, -2);
      queryParams[normalizedKey] = queryParams[normalizedKey] || [];
      queryParams[normalizedKey].push(normalizedValue);
    } else {
      if (normalizedValue !== null) {
        queryParams[key] = normalizedValue;
      }
    }
  }
  return queryParams;
}
function normalizeQueryParamValue(value) {
  if (value === "false") {
    return false;
  } else if (value === "true") {
    return true;
  } else if (value === null) {
    return "";
  } else {
    return value;
  }
}

export function getPathnamePart(pathname, length) {
  const pathnameArray = pathname && pathname.split("/");
  return pathnameArray?.length
    ? pathnameArray.slice(0, length + 1).join("/")
    : null;
}

// Synced with globals.css value
const MOBILE_WIDTH_LIMIT = 768;

export function mobileWindowWidth(width) {
  if (width <= MOBILE_WIDTH_LIMIT) {
    return true;
  }
}

function getWindowSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize(getWindowSize());
    }, 500);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export function useOutsideClick(action) {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        action();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, action]);

  return ref;
}

export function buildFiltersQuery(filters) {
  return Object.entries(filters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map((itemValue) => {
            return `${key}[]=${itemValue}`;
          })
          .join("&");
      } else {
        if (value) {
          return `${key}=${value}`;
        } else {
          return null;
        }
      }
    })
    .filter((v) => v)
    .join("&");
}

export function getQueryText(query, pageParam) {
  const pageQuery = pageParam && pageParam > 1 ? `page=${pageParam}` : null;
  return query
    ? `/?${query}${pageQuery ? `&${pageQuery}` : ""}`
    : pageQuery
      ? `/?${pageQuery}`
      : undefined;
}

export function removeEmpty(data) {
  return Object.fromEntries(
    Object.entries(data).filter(([_, v]) => !["null", null].includes(v))
  );
}

export function getValidFilters(data) {
  return Object.fromEntries(
    Object.entries(data)
      .filter(([_, v]) => v)
      .filter(([_, v]) => {
        const emptyArray = Array.isArray(v) && v.length === 0;
        return !["null"].includes(v) && !emptyArray;
      })
  );
}

export const toolsTitles = {
  workspace_tools: "Työkalut",
  risk_assessments: "Riskiarviot",
  responsibility_questionnaire: "Vastuullisuus",
  reclamations: "Reklamaatiot",
  feedbacks: "Projektipalaute",
  bid_groups: "Kilpailutuksen tiedot",
  services_documentations: "Ohjeistus",
  reference: "Referenssi",
  admin: "Admin",
};

export function consoleDebug(message = "", ...args) {
  const debugMode =
    window["LOG_LEVEL"] === "debug" ||
    localStorage.getItem("LOG_LEVEL") === "debug";

  if (!debugMode) {
    return;
  }

  try {
    throw new Error();
  } catch (e: any) {
    // NOTE: matches this function, the caller and the parent
    const allMatches = [...e.stack.matchAll(/ @|at (\w+) \(/g)].map(
      (item) => item[1]
    );

    // NOTE: match parent function name
    // const parentMatches = allMatches[2].match(/(\w+)@|at (\w+) \(/);

    console.log(allMatches.slice(1).join("."), message, ...args);
  }
}

const LOCALE_KEY = "i18nextLng";

export const getLangAsync = async () => localStorage.getItem(LOCALE_KEY);
export const setLangAsync = async (value) =>
  localStorage.setItem(LOCALE_KEY, value);
export const deleteLangAsync = async () => localStorage.removeItem(LOCALE_KEY);

export const getTitles = (t, formName, type, attributes) => {
  const arr = attributes.map((i) => {
    return [i, t(`${formName}.${type}.${i}`)];
  });
  return Object.fromEntries(arr);
};

// buildings full data
export function getBuildingsIds(project) {
  return project?.worksites.reduce(
    (acc, worksite) => acc.concat(worksite.buildingsIds),
    []
  );
}

function getFullBuildingsInfo(missingBuildingsInfo, buildings) {
  return missingBuildingsInfo
    ? buildings.map((building) => {
        const found = missingBuildingsInfo.find((i) => i.id === building.id);
        return found || building;
      })
    : buildings;
}

export function setFullBuildingsInfo(project, missingBuildingsInfo) {
  project.buildings = getFullBuildingsInfo(
    missingBuildingsInfo,
    project.buildings
  );
  project.buildings.forEach((building) => {
    if (building.worksite?.buildings) {
      building.worksite.buildings = getFullBuildingsInfo(
        missingBuildingsInfo,
        building.worksite.buildings
      );
    }
  });
}

export function formatDateTime(date) {
  return format("{DD}.{MM}.{YY} {HH}:{mm}")(new Date(date));
}
export function getDateTimeValue(date) {
  return date ? formatDateTime(date) : "-";
}

export function formatDate(date) {
  return format("{DD}.{MM}.{YYYY}")(new Date(date));
}
export function getDateValue(date) {
  return date ? formatDate(date) : "-";
}
export function sliceDate(date) {
  return date ? date.slice(0, 10) : "";
}
export function splitDate(date) {
  return date ? date.toISOString().split("T")[0] : "";
}

export function getDay(day) {
  if (day === "today") {
    return new Date();
  } else if (day === "tomorrow") {
    const tmr = new Date();
    tmr.setDate(tmr.getDate() + 1);
    return tmr;
  }
  return new Date();
}
export function getNumber(value) {
  return typeof value === "string" ? parseInt(value) : value;
}

export function orderItems(data, attr, asc) {
  return data.sort(([a], [b]) => {
    const order = a[attr] > b[attr] || !b[attr] ? 1 : -1;
    if (asc) {
      return order;
    } else {
      return order * -1;
    }
  });
}

export function capitalize(text) {
  return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
}

export function splitNumbers(str) {
  if (!str) return "";
  const newStr = str?.indexOf(".") > -1 ? parseFloat(str).toFixed(2) : str;
  const [whole, fraction] = newStr.split(".");
  const splited = getReversed(whole)
    ?.match(/.{1,3}/g)
    .join(" ");
  const result = compact([getReversed(splited), fraction]);
  return result?.join(".");
}
function getReversed(str) {
  return str?.split("").reverse().join("");
}

export function getUniqPosition(values: { position?: number }[], num: number) {
  let result = num;
  const found = values.find((i) => i.position === num);
  if (found) {
    result = getUniqPosition(values, num + 1);
  }
  return result;
}

export function getDataSortedByPosition<T>(data: { position?: number }[]): T[] {
  data.forEach((item, index) => {
    if (!item.position) {
      const positionVal = getUniqPosition(data, index + 1);
      item.position = positionVal;
    }
  });
  return sortBy(data, (i) => i.position);
}

export async function getDriveItemDownloadUrl({
  msClient,
  siteId,
  libraryId,
  itemId,
}: {
  msClient: any;
  siteId: string | undefined;
  libraryId: string | undefined;
  itemId: string;
}): Promise<string | undefined> {
  const parsedSiteId = siteId ? JSON.parse(siteId)[1] : undefined;
  const url = `/sites/${parsedSiteId}/drives/${libraryId}/items/${itemId}`;

  return await msClient
    .api(url)
    .version("v1.0")
    .get()
    .then(async (attachment: MicrosoftGraphTypes.DriveItem) => {
      return await attachment["@microsoft.graph.downloadUrl"];
    })
    .catch((error) => {
      const errorCode = JSON.parse(error.body).error.code;
      if (errorCode === "InvalidAuthenticationToken") {
        // TODO: Handle
      } else {
        actionReporter.notify(error);
      }
    });
}

/* functions for emails */
export function checkEmailFormat(value) {
  return /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}/i.test(value);
}
export function checkOutlookFormat(value) {
  return /\<[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\>/i.test(value);
}
export function extractEmailAndName(text) {
  if (!text) return {};

  let extractedName: string | undefined;
  if (checkOutlookFormat(text)) {
    extractedName = text.split("<")[0].trim();
  }

  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
  const match = text.match(emailRegex);
  const extractedEmail = match ? match[0] : "";

  return { extractedEmail, extractedName };
}

export function splitEmails(emails) {
  return emails
    .split(";")
    .map((email) => email.trim())
    .filter((i) => i);
}
export function checkAllEmailsFormats(value) {
  const emails = splitEmails(value);
  return emails.every((email) => checkEmailFormat(email));
  // if returns false, show error message for user
}
/* end - functions for emails */
