import { I18nLocalesEnum } from "../../../graphql";

interface ITranslations<T> {
  [key: string]: T;
}

export class HasTranslations<T> {
  private _translations!: ITranslations<T>;
  private getTranslations() {
    if (!this._translations) {
      this._translations = this["_data"]["translations"]?.reduce(
        (acc: ITranslations<T>, translation: T) => {
          acc[translation["locale"]] = translation;
          return acc;
        },
        {}
      );
    }
    return this._translations;
  }
  translate<F>(
    field: string,
    locale: I18nLocalesEnum,
    fallback: boolean = true
  ): F | undefined {
    const defaultLocale = I18nLocalesEnum.Fi;
    const translations = this.getTranslations();
    const localizedField = translations?.[locale]?.[field];
    const fallbackField = translations?.[defaultLocale]?.[field];

    if (field === "contentState") {
      let hasContent = locale === defaultLocale;

      if (localizedField?.blocks?.length > 0 && !hasContent) {
        const blocks = localizedField.blocks;

        for (let index = 0; index < blocks.length; index++) {
          const block = blocks[index];

          if (block.text?.trim().length > 0 || block.entityRanges?.length > 0) {
            hasContent = true;
            break;
          }
        }
      }

      if (localizedField && (hasContent || !fallback)) {
        return localizedField;
      } else if (fallbackField) {
        return fallbackField;
      } else {
        return this["_data"]?.[field];
      }
    }

    return localizedField || fallbackField || this["_data"]?.[field];
  }
}
