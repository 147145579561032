import actionReporter from "~/config/initializers/actionReporter";
import { getServerUrl } from "../helpers";
import denormalize from "denormalize-jsonapi";
import { voimaVersion } from "~/config/env";

class ApiError extends Error {
  name: string;
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.name = "ApiError";
    this.status = status;
  }
}

export default class RestApiClient {
  fetchAccessToken: () => string;
  fetchLocale: () => string;
  onAuthenticationError: () => void;

  constructor({
    fetchAccessToken,
    fetchLocale,
    onAuthenticationError,
  }: {
    fetchAccessToken?: () => string;
    fetchLocale?: () => string;
    onAuthenticationError?: () => void;
  } = {}) {
    this.fetchAccessToken = fetchAccessToken || (() => "");
    this.fetchLocale = fetchLocale || (() => "fi");
    this.onAuthenticationError = onAuthenticationError || (() => {});
  }

  getOfferSearchOptions() {
    return this.get("/web_api/search_options/offers");
  }

  getOrganizations({ query }) {
    return this.get(`/web_api/organizations?query=${query}`);
  }

  getProjectClientRepresentativeOptions({ query }) {
    return this.get(
      `/web_api/projects/client_representative_names?query=${query}`
    );
  }

  getWorkspaceClientRepresentativeOptions({ query }) {
    return this.get(
      `/web_api/workspaces/client_representative_names?query=${query}`
    );
  }

  workspaceAddresses({ query }) {
    return this.request(
      "GET",
      `/web_api/workspaces/workspace_addresses?query=${query}`
    ).then((res) => res.json());
  }

  workspaceManagers({ query }) {
    return this.request(
      "GET",
      `/web_api/workspaces/managers?query=${query}`
    ).then((res) => res.json());
  }

  getCurrentUser() {
    return this.get("/web_api/current_user").then((res) =>
      Object.assign(res, {
        latestSystemNotification: denormalize(res.latestSystemNotification)
          .data,
      })
    );
  }

  getOffers(query = "") {
    return this.newGet("/web_api/offers" + query);
  }

  getOffer(id) {
    return this.newGet(`/web_api/offers/${id}`);
  }

  getOfferById(id) {
    return this.newGet(`/web_api/offers/by_id/${id}`);
  }

  getProjectSearchOptions() {
    return this.get("/web_api/search_options/projects");
  }

  getWorkspaceSearchOptions() {
    return this.get("/web_api/search_options/workspaces");
  }

  getProjects(query = "") {
    return this.newGet("/web_api/projects" + query);
  }

  getProject(id, paramsQuery) {
    const query = paramsQuery ? `?${paramsQuery}` : "?simplified=false";
    return this.newGet(`/web_api/projects/${id}${query}`);
  }

  getProjectById(id) {
    return this.newGet(`/web_api/projects/by_id/${id}`);
  }

  getReclamation(id) {
    return this.newGet(`/web_api/reclamations/${id}`);
  }

  getRiskAssessment(id) {
    return this.newGet(`/web_api/risk_assessments/${id}`);
  }

  getProjectFeedback(id) {
    return this.newGet(`/web_api/surveys/${id}`);
  }

  createProjectReclamation({ projectId, data }) {
    return this.request("POST", `/web_api/projects/${projectId}/reclamations`, {
      data,
    }).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  updateProjectReclamation({ id, data }) {
    return this.request("PATCH", `/web_api/reclamations/${id}`, { data }).then(
      (res) =>
        res.json().then((data) => {
          const resource = data.data;

          return { data: resource, headers: res.headers, response: res };
        })
    );
  }

  createRiskAssessment({ data, workspaceId }) {
    if (workspaceId) {
      return this.createWorkspaceRiskAssesment({ workspaceId, data });
    }
  }

  createProjectRiskAssessment({ projectId, data }) {
    return this.request(
      "POST",
      `/web_api/projects/${projectId}/risk_assessments`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  createOfferRiskAssessment({ offerId, data }) {
    return this.request(
      "POST",
      `/web_api/offers/${offerId}/risk_assessments`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  createWorkspaceRiskAssesment({ workspaceId, data }) {
    return this.request(
      "POST",
      `/web_api/workspaces/${workspaceId}/risk_assessments`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  updateProjectRiskAssessment({ id, data }) {
    return this.request("PATCH", `/web_api/risk_assessments/${id}`, data).then(
      (res) =>
        res.json().then((data) => {
          const resource = data;

          return { data: resource, headers: res.headers, response: res };
        })
    );
  }

  getProjectFeedbacks(projectId) {
    return this.newGet(`/web_api/projects/${projectId}/surveys`);
  }

  createProjectFeedback({ projectId, data }) {
    return this.request("POST", `/web_api/projects/${projectId}/surveys`, {
      data,
    }).then((res) =>
      res.json().then((data) => {
        return data.data;
      })
    );
  }

  updateFeedbackAnswer({ answerId, data }) {
    return this.request("PUT", `/web_api/survey_answers/${answerId}`, {
      data,
    }).then((res) =>
      res.json().then((data) => {
        const resource = denormalize(data, ["survey"])?.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  getWorkspaceFeedbacks(workspaceId) {
    return this.newGet(`/web_api/workspaces/${workspaceId}/surveys`);
  }

  createWorkspaceFeedback({ workspaceId, data }) {
    return this.request("POST", `/web_api/workspaces/${workspaceId}/surveys`, {
      data,
    }).then((res) =>
      res.json().then((data) => {
        return data.data;
      })
    );
  }

  updateFeedback({ surveyId, data }) {
    return this.request("PUT", `/web_api/surveys/${surveyId}`, data).then(
      (res) =>
        res.json().then((data) => {
          return data.data;
        })
    );
  }

  deleteFeedback({ surveyId }) {
    return this.request("DELETE", `/web_api/surveys/${surveyId}`).then((res) =>
      res.json()
    );
  }

  getOwnReclamations(query = "") {
    return this.newGet(`/web_api/reclamations/own${query}`);
  }

  getRiskAssessments(project) {
    if (project.type === "offer") {
      return this.getOfferRiskAssessments(project.valueframeId);
    } else if (project.type === "project") {
      return this.getProjectRiskAssessments(project.valueframeId);
    }
  }

  getProjectRiskAssessments(projectId) {
    return this.newGet(`/web_api/projects/${projectId}/risk_assessments`);
  }

  getWorkspaceRiskAssesments(workspaceId) {
    return this.newGet(`/web_api/workspaces/${workspaceId}/risk_assessments`);
  }

  getOfferRiskAssessments(offerId) {
    return this.newGet(`/web_api/offers/${offerId}/risk_assessments`);
  }

  sendApproval(id, data) {
    return this.request("POST", `/web_api/risk_assessments/${id}/approval`, {
      approval: data,
    }).then((res) =>
      res.json().then((data) => {
        return { data: data, headers: res.headers, response: res };
      })
    );
  }

  cancelApproval(id) {
    return this.request(
      "DELETE",
      `/web_api/risk_assessments/${id}/approval`
    ).then((res) =>
      res.json().then((data) => {
        return { data: data, headers: res.headers, response: res };
      })
    );
  }

  decideApproval(id, data) {
    return this.request(
      "POST",
      `/web_api/risk_assessments/${id}/approval/decision`,
      { approval: data }
    ).then((res) =>
      res.json().then((data) => {
        const resource = denormalize(data, [
          "formGroups",
          "approval",
          "responsible",
          "creator",
        ])?.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  searchContentBlocks({ query }) {
    return this.get(`/web_api/content_blocks/search?q=${query}`);
  }

  updateContentBlocksOrder({
    library,
    contentBlockOrder,
    knowledgeArea,
    contentCategoryId,
  }) {
    const body = {
      library,
      contentBlockOrder,
      knowledgeArea: knowledgeArea || "frontpage",
      contentCategoryId,
    };
    return this.request(
      "PATCH",
      `/web_api/content_blocks/update_order`,
      body
    ).then((res) => res.json());
  }

  createContentBlock(body) {
    return this.request("POST", `/web_api/content_blocks`, body).then((res) =>
      res.json()
    );
  }

  updateContentBlock(id, body) {
    return this.request("PATCH", `/web_api/content_blocks/${id}`, body).then(
      (res) => res.json()
    );
  }

  createOffer(body) {
    return this.request("POST", `/web_api/offers`, body).then((res) =>
      res.json()
    );
  }

  updateOffer(id, body) {
    return this.request("PUT", `/web_api/offers/${id}`, body).then((res) =>
      res.json()
    );
  }

  async createSystemNotification(body) {
    const response = await this.request(
      "POST",
      `/web_api/system_notifications`,
      body
    ).then((res) => res.json());
    return denormalize(response)?.data;
  }

  async updateSystemNotification(id, body) {
    const response = await this.request(
      "PATCH",
      `/web_api/system_notifications/${id}`,
      body
    ).then((res) => res.json());
    return denormalize(response)?.data;
  }

  async deleteSystemNotification(id) {
    return await this.request("DELETE", `/web_api/system_notifications/${id}`);
  }

  createProject(body) {
    return this.request("POST", `/web_api/projects`, body).then((res) =>
      res.json()
    );
  }

  updateProject(id, body) {
    return this.request("PUT", `/web_api/projects/${id}`, body).then((res) =>
      res.json()
    );
  }

  projectAddresses({ query }) {
    return this.request(
      "GET",
      `/web_api/project_addresses?query=${query}`
    ).then((res) => res.json());
  }

  deleteContentBlock(id) {
    return this.request("DELETE", `/web_api/content_blocks/${id}`);
  }

  createFeedback(body) {
    return this.request("POST", "/web_api/feedbacks", body).then((res) =>
      res.json()
    );
  }

  joinOffer(offerId) {
    return this.request("POST", `/web_api/offers/${offerId}/memberships`).then(
      (res) => {
        if (res.status === 202) {
          return true;
        } else {
          return false;
        }
      }
    );
  }

  joinProject(projectId) {
    return this.request(
      "POST",
      `/web_api/projects/${projectId}/memberships`
    ).then((res) => {
      if (res.status === 202) {
        return true;
      } else {
        return false;
      }
    });
  }

  getDepartments() {
    return this.get("/web_api/departments");
  }

  getSubprojects(id) {
    return this.newGet(`/web_api/projects/${id}/workorders`);
  }

  getPreviewNetworkDirectoryPath(workspaceId, workspaceType, data) {
    const workspaceUri = {
      Project: "projects",
      Offer: "offers",
    }[workspaceType];
    return this.request(
      "POST",
      `/web_api/${workspaceUri}/${workspaceId}/preview_network_directory_path`,
      data
    ).then((res) => res.json());
  }

  updateProjectAddress(id) {
    return this.request("POST", `/web_api/projects/${id}/update_address`).then(
      (res) => res.json()
    );
  }

  /**
   * service types
   * */
  updateService(type, id, data) {
    if (type === "area") {
      return this.updateServiceArea(id, data);
    } else if (type === "type") {
      return this.updateServiceType(id, data);
    } else {
      return this.updateServiceProduct(id, data);
    }
  }

  getServiceAreas(query = "") {
    return this.get(`/web_api/service_areas${query}`);
  }
  updateServiceArea(id, data) {
    return this.request("PATCH", `/web_api/service_areas/${id}`, data).then(
      (res) => res.json()
    );
  }

  getServiceTypes() {
    return this.get("/web_api/service_types");
  }
  updateServiceType(id, data) {
    return this.request("PATCH", `/web_api/service_types/${id}`, data).then(
      (res) => res.json()
    );
  }

  getServiceProducts() {
    return this.get("/web_api/service_products");
  }
  updateServiceProduct(id, data) {
    return this.request("PATCH", `/web_api/service_products/${id}`, data).then(
      (res) => res.json()
    );
  }

  getServiceAreasWithTypesAndProducts() {
    return this.get(
      `/web_api/service_areas?include=serviceTypes,serviceTypes.serviceProducts`
    );
  }
  /* end - service types */

  /**
   * service libraries
   * */
  getDocumentationLibraries() {
    return this.get("/web_api/documentation_libraries");
  }
  getDocumentationLibrary(slug) {
    return this.get(`/web_api/documentation_libraries/${slug}`);
  }
  getDocumentationLibraryByService(kind, id) {
    if (kind === "serviceArea") {
      return this.get(`/web_api/service_areas/${id}/documentation_library`);
    } else {
      return this.get(`/web_api/service_types/${id}/documentation_library`);
    }
  }
  createDocumentationLibrary(data) {
    return this.request("POST", `/web_api/documentation_libraries`, data).then(
      (res) => res.json()
    );
  }
  updateDocumentationLibrary(slug, data) {
    return this.request(
      "PATCH",
      `/web_api/documentation_libraries/${slug}`,
      data
    ).then((res) => res.json());
  }
  archiveDocumentationLibrary(slug) {
    return this.request("DELETE", `/web_api/documentation_libraries/${slug}`);
  }
  getServiceProductsForLibraryArea(slug, area) {
    return this.get(
      `/web_api/documentation_libraries/${slug}/service_products?areaName=${area}`
    );
  }
  /* end - service libraries */

  /**
   * offer bidding
   * */
  getOfferBidGroups(id) {
    return this.newGet(`/web_api/offers/${id}/bid_groups`);
  }

  getWorkspaceOfferBidGroups(id) {
    return this.newGet(`/web_api/workspaces/${id}/offer_bid_groups`);
  }

  createOfferBidGroup({ id, data }) {
    return this.request("POST", `/web_api/offers/${id}/bid_groups`, data).then(
      (res) =>
        res.json().then((data) => {
          const resource = data.data;

          return { data: resource, headers: res.headers, response: res };
        })
    );
  }
  getOfferBidGroup(bidGroupId) {
    return this.newGet(`/web_api/offer_bid_groups/${bidGroupId}`);
  }
  updateOfferBidGroup({ bidGroupId, data }) {
    return this.request(
      "PATCH",
      `/web_api/offer_bid_groups/${bidGroupId}`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }
  updateOfferBid({ bidId, data }) {
    return this.request("PATCH", `/web_api/offer_bids/${bidId}`, data).then(
      (res) => res.json()
    );
  }
  updateOfferBids({ data }) {
    return this.request("PATCH", `/web_api/offer_bids`, data).then((res) =>
      res.json()
    );
  }
  getMunicipalities() {
    return this.newGet("/web_api/municipalities");
  }
  getDBCompanies() {
    return this.newGet("/web_api/companies");
  }
  getCompaniesFromRegistry(query = "") {
    return this.newGet(`/web_api/companies/search${query}`);
  }
  createCompanyFromRegistry(data) {
    return this.request("POST", `/web_api/companies`, data).then((res) =>
      res.json()
    );
  }
  getPriceCategoryNames(id) {
    return this.newGet(`/web_api/offers/${id}/bid_groups/price_categories`);
  }
  getPriceCategoriesOptions() {
    return this.newGet(`/web_api/offer_bids/price_categories`);
  }
  /* end - offer bidding */

  /**
   * project references
   * */
  getReferenceFields(query) {
    return this.newGet(`/web_api/project_references/fields${query}`);
  }
  getReferenceModel(id, query) {
    return this.newGet(
      `/web_api/workspaces/${id}/reference/new${
        query ? `?${query}` : ""
      }`
    );
  }
  getProjectReference(id, query) {
    return this.newGet(
      `/web_api/workspaces/${id}/reference${
        query ? `?${query}` : ""
      }`
    );
  }
  getProjectReferencesByIds(query) {
    return this.newGet(`/web_api/project_references/by_ids?${query}`);
  }
  saveProjectReference({ id, data, query }) {
    return this.request(
      "PUT",
      `/web_api/workspaces/${id}/reference${query}`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }
  saveReferenceVisibleFields({ id, data }) {
    return this.request(
      "PUT",
      `/web_api/workspaces/${id}/reference/visible_field_sets`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  getReferenceTemplates() {
    return this.newGet(`/web_api/project_reference_templates`);
  }
  getReferenceTemplate(id) {
    return this.newGet(`/web_api/project_reference_templates/${id}`);
  }
  createReferenceTemplate({ data }) {
    return this.request(
      "POST",
      `/web_api/project_reference_templates`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }
  updateReferenceTemplate({ id, data }) {
    return this.request(
      "PUT",
      `/web_api/project_reference_templates/${id}`,
      data
    ).then((res) =>
      res.json().then((data) => {
        const resource = data.data;

        return { data: resource, headers: res.headers, response: res };
      })
    );
  }
  deleteReferenceTemplate(id) {
    return this.request("DELETE", `/web_api/project_reference_templates/${id}`);
  }
  updateReferenceTemplatesOrder(data) {
    return this.request(
      "POST",
      `/web_api/project_reference_templates/order`,
      data
    ).then((res) => res.json());
  }

  // search references
  getReferenceSeachFields(query) {
    return this.newGet(`/web_api/project_references/search_fields${query}`);
  }
  getProjectReferences(data, query) {
    return this.request(
      "POST",
      `/web_api/project_references/search${query ? `?${query}` : ""}`,
      data
    ).then((res) =>
      res.json().then((data) => {
        return { data: data, headers: res.headers, response: res };
      })
    );
  }
  getReferenceSearchTemplates() {
    return this.newGet(`/web_api/project_references/search_templates`);
  }
  getReferenceSearchTemplate(id) {
    return this.newGet(`/web_api/project_references/search_templates/${id}`);
  }
  getEmployeeReferences(id) {
    return this.newGet(`/web_api/employees/${id}/references`);
  }
  /* end - project references */

  /**
   * SDG
   * */
  getProjectResponsibilityQuestionnaire(project, identifier) {
    if (project.type === "offer") {
      return this.newGet(`/web_api/offers/${identifier}/responsibility_form`);
    } else if (project.type === "project") {
      return this.newGet(`/web_api/projects/${identifier}/responsibility_form`);
    } else {
      return this.newGet(
        `/web_api/workspaces/${identifier}/responsibility_form`
      );
    }
  }

  saveQuestionnaire(id, type, data) {
    return this.request(
      "POST",
      `/web_api/${type}/${id}/responsibility_form`,
      data
    ).then((res) => res.json());
  }

  getSDGActionOptions(type, valueframeId) {
    if (type === "offers") {
      return this.newGet(`/web_api/offers/${valueframeId}/suggested_actions`);
    } else if (type === "projects") {
      return this.newGet(`/web_api/projects/${valueframeId}/suggested_actions`);
    } else if (type === "workspaces") {
      return this.newGet(
        `/web_api/workspaces/${valueframeId}/suggested_actions`
      );
    }
  }
  /* end - SDG */

  /**
   * admin tab
   * */
  importFromVF(id, type) {
    if (type === "project") {
      return this.importProjectVF(id);
    } else {
      return this.importOfferVF(id);
    }
  }
  importOfferVF(id) {
    return this.request("POST", `/web_api/offers/${id}/import_valueframe`).then(
      (res) => res.json()
    );
  }
  importProjectVF(id) {
    return this.request(
      "POST",
      `/web_api/projects/${id}/import_valueframe`
    ).then((res) => res.json());
  }
  importProjectMap(id) {
    return this.request(
      "POST",
      `/web_api/projects/${id}/import_projektikartta`
    ).then((res) => res.json());
  }
  /* end - admin tab */

  getExternalLink({ entityType, entityIdentifier, linkType }) {
    return this.newGet(
      `/web_api/external_links/${entityType}/${entityIdentifier}/${linkType}`
    );
  }

  /**
   * workspace tools
   * */
  getWorkspaceTools({ workspaceId, workspaceType }) {
    return this.newGet(
      `/web_api/workspace_tools?workspaceId=${workspaceId}&workspaceType=${workspaceType}`
    );
  }

  getWorkspaceTool({ id }) {
    return this.newGet(`/web_api/workspace_tools/${id}`);
  }

  getWorkspaceReclamations(workspaceId) {
    return this.newGet(`/web_api/workspaces/${workspaceId}/reclamations`);
  }

  createWorkspaceReclamation({ id, data }) {
    return this.request("POST", `/web_api/workspaces/${id}/reclamations`, {
      data,
    }).then((res) =>
      res.json().then((data) => {
        const resource = data.data;
        return { data: resource, headers: res.headers, response: res };
      })
    );
  }

  updateWorkspaceReclamation({ id, data }) {
    return this.request("PATCH", `/web_api/reclamations/${id}`, { data }).then(
      (res) =>
        res.json().then((data) => {
          const resource = data.data;

          return { data: resource, headers: res.headers, response: res };
        })
    );
  }

  enableWorkspaceTool(body) {
    return this.request("POST", `/web_api/workspace_tools`, body).then((res) =>
      res.json()
    );
  }

  restartWorkspaceTool({ id, payload }) {
    return this.request(
      "POST",
      `/web_api/workspace_tools/${id}/restart`,
      payload
    ).then((res) => res.json());
  }

  disableWorkspaceTool({ id }) {
    return this.request("DELETE", `/web_api/workspace_tools/${id}`).then(
      (res) => res.json()
    );
  }

  saveWorkspaceTools({ workspaceId, workspaceType, data }) {
    return this.request("PUT", `/web_api/workspace_tools`, {
      workspaceId,
      workspaceType,
      data,
    }).then((res) => res.json());
  }
  /* end - workspace tools */

  /**
   * Workorders
   * */
  getWorkorder(projectId, workorderId) {
    return this.newGet(
      `/web_api/projects/${projectId}/workorders/${workorderId}`
    );
  }
  updateWorkorder(projectId, workorderId, data) {
    return this.request(
      "PUT",
      `/web_api/projects/${projectId}/workorders/${workorderId}`,
      data
    ).then((res) => res.json());
  }
  /* end - workorders */

  touchProjectReferenceExportLog({ ids }) {
    return this.request("POST", `/web_api/export_logs/project_references`, {
      projectReferenceIds: ids,
    }).then((result) => result.json());
  }

  touchResponsibilityFormExportLog({
    workspaceIds = [],
  }) {
    return this.request("POST", `/web_api/export_logs/responsibility_forms`, {
      workspaceIds,
    }).then((result) => result.json());
  }

  request(method: string, url: string, body?: any): Promise<any> {
    const api = this;

    return new Promise((resolve, reject) => {
      actionReporter.setContext({
        method,
        url,
        body,
      });
      const reporterTransactionId = actionReporter?.transactionId;
      const locale = api.fetchLocale?.();
      const accessToken = api.fetchAccessToken?.();
      let params = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "app-version": voimaVersion,
          ...(locale ? { "Accept-Language": locale } : {}),
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          ...(reporterTransactionId ? { "reporter-transaction-id": reporterTransactionId } : {}),
        },
      };
      if (body) {
        params.body = JSON.stringify(body);
      }
      fetch(getServerUrl(url), params).then(
        async (res) => {
          if (res.ok) {
            resolve(res);
          } else {
            if (res.status === 401) {
              this.onAuthenticationError();
            } else if (res.status === 403) {
              reject(new ApiError("No access", res.status));
            } else if (res.status === 422) {
              res.json().then((data) => {
                actionReporter.setContext({
                  res,
                  data,
                });
                reject(new ApiError("Unprocessable Entity", res.status));
              });
            } else {
              res.text().then((responseText) => {
                actionReporter.setContext({
                  status: res.status,
                  responseText,
                });
                reject(new ApiError("API returned error", res.status));
              });
            }
          }
        },
        (error) => {
          actionReporter.setContext({
            error,
          });

          reject(new ApiError("API returned incorrect response", error));
        }
      );
    });
  }

  newGet(url) {
    const api = this;

    function executeFetch(resolve, reject) {
      const reporterTransactionId = actionReporter?.transactionId;
      const locale = api.fetchLocale?.();
      const accessToken = api.fetchAccessToken?.();
      fetch(getServerUrl(url), {
        headers: {
          "Content-Type": "application/json",
          "app-version": voimaVersion,
          ...(locale ? { "Accept-Language": locale } : {}),
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          ...(reporterTransactionId ? { "reporter-transaction-id": reporterTransactionId } : {}),
        },
      }).then(
        (res) => {
          if (res.ok) {
            res
              .json()
              .then((data) =>
                resolve({ data, headers: res.headers, response: res })
              );
          } else {
            if (res.status === 401) {
              api.onAuthenticationError();
            } else if (res.status === 403) {
              reject(new ApiError("No access", res.status));
            } else {
              res.text().then((responseText) => {
                actionReporter.setContext({
                  status: res.status,
                  responseText,
                });
                reject(new ApiError("API returned error", res.status));
              });
            }
          }
        },
        (error) => {
          actionReporter.setContext({
            error,
          });

          reject(new ApiError("API returned incorrect response", error));
        }
      );
    }
    return new Promise((resolve, reject) => {
      actionReporter.setContext({
        url: url,
      });
      executeFetch(resolve, reject);
    });
  }

  get(url) {
    const api = this;

    function executeFetch(resolve, reject) {
      const reporterTransactionId = actionReporter?.transactionId;
      const locale = api.fetchLocale?.();
      const accessToken = api.fetchAccessToken?.();
      fetch(getServerUrl(url), {
        headers: {
          "Content-Type": "application/json",
          "app-version": voimaVersion,
          ...(locale ? { "Accept-Language": locale } : {}),
          ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
          ...(reporterTransactionId ? { "reporter-transaction-id": reporterTransactionId } : {}),
        },
      }).then((res) => {
        if (res.ok) {
          resolve(res.json());
        } else {
          if (res.status === 401) {
            api.onAuthenticationError();
          } else if (res.status === 403) {
            reject(new ApiError("No access", res.status));
          } else {
            res.text().then((responseText) => {
              actionReporter.setContext({
                status: res.status,
                responseText,
              });
              reject(new ApiError("API returned error", res.status));
            });
          }
        }
      });
    }

    return new Promise((resolve, reject) => {
      actionReporter.setContext({
        url: url,
      });
      executeFetch(resolve, reject);
    });
  }
}
